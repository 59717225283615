import React, { useState } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'
import styled from 'styled-components'
import media from "../media"
import Fab from "@material-ui/core/Fab";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import ArrowRight from "@material-ui/icons/ArrowRight";



const StyledBG = styled.div`
background-color:#242424;
padding:10% 0;
`

const StyledCarouselContainer = styled.div`
display:flex;
flex-direction:row;
justify-content:flex-start;
align-items:center;
background-color:white;
margin:auto;
`
const StyledCarouselContent = styled.div`
display:flex;
flex-direction:column;
align-items:center;
`
const StyledImageConainer = styled.div`
/* min-width:280px; */
width:100vw;
`
const StyledQuote = styled.p`
color:black;
font-family:sans-serif;
font-size:1rem;
padding-top:15%;


`
const StyledAuthor = styled.p`
color:black;
font-family:sans-serif;
font-size:.7rem;
`
const StyledPrevButton = styled(Fab)`
/* display:none; */
width:50px;
height:50px;
position:absolute;
left:10px;

${media.mobileS`
top:4550px;
`}
${media.mobileM`
top:4720px;
`}
${media.mobileL`
top:4930px;
`}
z-index:0;
`
const StyledNextButton = styled(Fab)`
/* display:none; */
width:50px;
height:50px;
position:absolute;
right:10px;
${media.mobileS`
top:4550px;
`}
${media.mobileM`
top:4720px;
`}
${media.mobileL`
top:4930px;
`}
z-index:0;
`

const StyledQuoteContainer = styled.div`
display:flex;
flex-direction:column;
width:70%;
padding-left:5%;


`
const StyledMobileSliderTopWrapper=styled.div`

/* z-index: 99 !important; */
${media.mobileS`
display:block;
`}
${media.laptop`
display:none;
`}
`


const Carousel = () => {
  const [index, setIndex] = useState(0)
  const { allFile } = useStaticQuery(
    graphql`
      query {
        allFile(
          sort: { fields: name, order: DESC }
          filter: { relativeDirectory: { eq: "mobileSlides" } }
        ) {
          edges {
            node {
              id
              name
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    `
  )
  const carouselText = [
  // {
  //   quote:"RTrade’s approach with Temporal has the potential to fundamentally change the way enterprises manage data.",
  //   author:"- Jay Breakstone, Founder, LIQID"
  // },
  
  {
    quote:"We have partnered with RTrade to create the planet’s first use case that guarantees trust, fair trade, anti-smuggling, anti-trafficking, and anti-crime chain of custody from true source to customer, using IPFS, Oracles, and Smart Contracts.",
    author:"- Michael H Peters, Founder, TrustedBlockchain"
  },
  {quote:  "\"RTrade technologies not only provides us with the perfect solution of decentralized storage, but also give us a fresh new outlook on how the new blockchain storage industry will perform in the next 10 years\"",
  author: "- Charles Cao, Founder, Nebula AI"
 },
 {
  quote:"\"RTrade has been making huge progress over the past few months in optimizing IPFS and related technologies. With this partnership, Textile is super excited to be able to leverage this work, and provide increased access for developers through RTrade-hosted Textile Cafes.\"",
  author:"- Carson Farmer, Lead Data Scientist, Textile"
},
  ]
  // console.log(carouselText[0]);

  //Minus 1 for array offset from 0
  const length = allFile.edges.length - 1
  const handleNext = () =>
  //goes back to 0 if reach max length
    index === length ? setIndex(0) : setIndex(index + 1)
  const handlePrevious = () =>
    index === 0 ? setIndex(length) : setIndex(index - 1)
  const { node } = allFile.edges[index]
  return (
    <StyledMobileSliderTopWrapper>
    <StyledBG>
    <StyledCarouselContainer>
      {/* <StyledPrevButton onClick={() => handlePrevious()}>Previous</StyledPrevButton> */}
      <StyledPrevButton color="primary" aria-label="prev" onClick={() => handlePrevious()}>
        <ArrowLeft />
      </StyledPrevButton>
      <StyledCarouselContent>  
        <StyledImageConainer>
          <Img
            fluid={node.childImageSharp.fluid}
            key={node.id}
            // alt={node.name.replace(/-/g, ' ').substring(2)}
          />
        </StyledImageConainer>
        <StyledQuoteContainer>
        <StyledQuote>{carouselText[index].quote}</StyledQuote>
        <StyledAuthor>{carouselText[index].author}</StyledAuthor>
        </StyledQuoteContainer>

      </StyledCarouselContent>
      <StyledNextButton color="primary" aria-label="next" onClick={() => handleNext()}>
        <ArrowRight />
      </StyledNextButton>
      {/* <StyledNextButton onClick={() => handleNext()}>Next</StyledNextButton > */}
    </StyledCarouselContainer>
    </StyledBG>
   </StyledMobileSliderTopWrapper>
  )
}


export default Carousel

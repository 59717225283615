import React from 'react'
import styled from 'styled-components'
// import Header from '../../header/header'
import media from '../../../media'
import Developer from '../../../../images/product/temporal/features/developer.svg'
import Checkmark from '../../../../images/product/temporalX/icons/checkmark.svg'

const StyledFeatureContainer = styled.div`
display:flex;
justify-content:space-around;
background-color:#212121;

${media.mobileS`
  flex-direction:column;
  align-items:center;
`}
${media.laptop`
flex-direction:row;
padding-top:8%;

`}
`

const StyledFeatureHeader = styled.h1`
${media.mobileS`
width:100%;
font-size:1.5rem;
margin-top:50px;
`}
${media.laptop`

margin-top:10%;
padding-left:15%;

`}
color:orange;
`
const StyledFeatureBody = styled.p`

${
    media.mobileS`
   width:96%;
   font-size:0.9rem;
    `
  }
  ${
    media.tablet`
   width:80%;
   padding-left:15%;
   font-size:1rem;

    `
  }
  
color:white;
font-family:sans-serif;

`

const StyledLinks = styled.div`
display:flex;
flex-direction:row;
flex-wrap:wrap;
align-items:center;

${media.mobileS`
  width:100%;
  justify-content:flex-start;
`}
${media.laptop`
justify-content:space-around;
padding-left:15%;
padding-right:20%;
`}



`
const StyledLink = styled.a`
display:flex;
flex-direction:row;
justify-content:space-between;
align-items:stretch;
text-decoration:underline;
color: orange;
${media.mobileS`
width:70%;
`}
${media.laptop`
width:50%;
`}

`
const StyledLinkText = styled.p`
${ media.mobileS`
   width:96%;
   font-size:0.9rem;
   padding-left:3%;
    `
  }
  ${media.tablet`
    width:80%;
   font-size:0.9rem;
    `
  }

color: orange;
font-family:sans-serif;
`
const StyledLinkContainer = styled.div`
width:35px;
` 
const StyledTextContent = styled.div`
display:flex;
flex-direction:column;
justify-content:space-around;
${media.mobileS`
padding-left:3%;
`}
${media.laptop`
padding-left:0;
`}
`
const StyledFeatureImageContainer = styled.div`

${media.mobileS`
padding-right:0%;
width:90%;
`}
${media.tablet`
padding-right:10%;
width:70%;
`}
`

const publicPriavte = () => (
   
        <StyledFeatureContainer>
            <StyledTextContent>
                <StyledFeatureHeader>
                    Developer Driven
                </StyledFeatureHeader>

                <StyledFeatureBody>
                Build production-ready Apps/DApps on top of IPFS easily with modern tools and languages such as Golang and JavaScript. Get access to our APIs instantly to get started. 
                </StyledFeatureBody>

                <StyledLinks>
                    <StyledLink href="https://github.com/RTradeLtd/Temporal">
                        {/* <StyledLinkContainer>
                             <img src={Checkmark}/>
                        </StyledLinkContainer> */}
                        <StyledLinkText>
                        Libraries &amp; SDKs
                        </StyledLinkText>
                    </StyledLink>
                    <StyledLink href="https://play.temporal.cloud/">
                        {/* <StyledLinkContainer>
                             <img src={Checkmark}/>
                        </StyledLinkContainer> */}
                        <StyledLinkText>
                        Test Environment
                        </StyledLinkText>
                    </StyledLink>
                    <StyledLink href="https://t.me/RTradeTEMPORAL">
                        {/* <StyledLinkContainer>
                            <img src={Checkmark}/>
                        </StyledLinkContainer> */}
                        <StyledLinkText>
                            Online Support
                        </StyledLinkText>
                 </StyledLink>
                        <StyledLink href="/dev">
                        {/* <StyledLinkContainer>
                            <img src={Checkmark}/>
                        </StyledLinkContainer> */}
                        <StyledLinkText>
                            In-depth Documentation
                        </StyledLinkText>
                    </StyledLink>
                </StyledLinks>
            </StyledTextContent>
            <StyledFeatureImageContainer>
                <img src={Developer}/>
            </StyledFeatureImageContainer>
        </StyledFeatureContainer>


      )

  
export default publicPriavte

import React from 'react'
import ProductTemporalBG from './productTeporalBGtop'
import ProductPartnersTop from './productPartnersTop'
import styled from 'styled-components'
import Header from '../../header/header'
import media from '../../media'
import {StaticQuery,graphql} from 'gatsby'
import Img from 'gatsby-image'
import PublicPrivate from "./features/publicPrivate"
import OrganizationalManagement from "./features/organizationalManagement"
import DeveloperDriven from './features/developerDriven'
import ProductShills from './productShills'

const StyledProductContainer = styled.div`
display:flex;
flex-direction:column;
justify-content:space-around;
padding: 10% 8%;
`
const StyledTemporalHeader = styled.h1`
${media.mobileS`
width:100%;
font-size:2rem;
margin-top:100px;
`}
${media.laptop`
margin-top:0;

`}
color:orange;


`
const StyledAboutSubHeader = styled.h2`
text-transform:capitalize;
${
    media.mobileS`
   font-size:1.5rem;
    `
  }
  ${
    media.tablet`
   font-size:2rem;
    `
  }

color:white;

padding-top:5%;
`
const StyledAboutText = styled.p`

${
    media.mobileS`
   width:96%;
   font-size:0.9rem;
    `
  }
  ${
    media.tablet`
   width:60%;
   font-size:1rem;
    `
  }
  
color:white;
font-family:sans-serif;

`
const StyledTextDecal = styled.div`
background-color:orange;
width:15%;
height:4px;
/* margin-bottom:5%; */
`
const StyledImageContainer = styled.div`
width:80%;
padding-right:5%;
padding-top:5%;
/* &:hover{
    transform: scale(1.2);
} */
${media.mobileS`
margin:auto;
`}
`
const StyledProductSctionContainer = styled.div`
display:flex;
justify-content:space-around;
${media.mobileS`
flex-direction:column;
`}
${media.laptop`
flex-direction:row;
`}
`

const productTemporal = () => (
  <StaticQuery
  query={ImagesQ}
  render={data => 
    (
    
       <ProductTemporalBG>
        <Header/>
        <StyledProductSctionContainer>
          <StyledProductContainer>
            <StyledTemporalHeader >
            Start Building With Temporal Today
            </StyledTemporalHeader >
            <StyledTextDecal/>
            <StyledAboutSubHeader/>
              <StyledAboutText>
              Whether you’re looking to connect to the IPFS network, build an application, or simply plug into your existing service, Temporal has all the open-source tools to help you build with increased flexibility, scalability and reliability.
              </StyledAboutText>
          </StyledProductContainer>
          <StyledImageContainer>
           <Img fluid={data.glowingBall.childImageSharp.fluid}/>
          </StyledImageContainer>
        </StyledProductSctionContainer>
        <ProductPartnersTop/>
        <PublicPrivate/>
        <OrganizationalManagement/>
        <DeveloperDriven/>
        <ProductShills/>

       </ProductTemporalBG>
    )
}
  
/>
)

export const ImagesQ = graphql`
query {
  glowingBall: file(relativePath: { eq: "product/temporal/glowingball.png" }) {
    childImageSharp {
      fluid(maxWidth: 800) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`

export default productTemporal

import React from 'react'
import Layout from "../components/layout"
// import SEO from "../components/seo"
import ProductSection from '../components/product/temporal/productTemporal'
import MobileHeader from '../components/header/headerMobile'
import ProductPricing from '../components/product/temporal/productPricing'
import Carousel from '../components/testimonyCarousel/carousel'
import MobileCarousel from '../components/testimonyCarousel/carouselMobile'
import {Helmet} from "react-helmet"

const product = () => (
    <Layout>
         <div className="application">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Temporal</title>
          <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
          <meta property="og:title" content="Temporal"/>
          <meta property="og:description" content="Start Building With Temporal Today"/>
          <meta property="og:image" content="https://temporal.cloud/temporalProduct.png"/>
          <meta property="og:url" content="https://temporal.cloud/temporal"/>
          <meta property="og:type" content="website"/>
          <meta property="og:locale" content="en_US"/>
          <meta name="twitter:card" content="summary_large_image"/>
          <meta name="twitter:site" content="@Temporalcloud"/>
          <meta name="twitter:title" content="Temporal"/>
          <meta name="twitter:description" content="Start Building With Temporal Today"/>
          <meta name="twitter:image" content="https://temporal.cloud/temporalProduct.png"/>      
        </Helmet>
      </div>
    {/* <SEO title="Temporal" /> */}
    <MobileHeader/>
    <ProductSection/>
    <Carousel/>
    <MobileCarousel/>
    <ProductPricing/>

     
 </Layout>
    )


export default product
